import React from 'react';

import {graphql, useStaticQuery} from 'gatsby';
import {PodcastModal} from 'src/components/editorial/PodcastModal';
import {SEOWithQuery} from 'src/components/shared';
import {PODCAST} from 'src/routes/paths';

import {Maybe, SanityEditorial} from 'types/generated';

import {GridCard} from '../../components/editorial/GridCard';
import {Pagination} from '../../components/shared/Pagination';
import {SupportingContent} from '../editorial/SupportingContent';
import {InsightsNavigation} from '../insightsNavigation';

interface PodcastsProps {
  articles: [Maybe<SanityEditorial>];
  limit: number;
  currentPage: number;
  numPages: number;
}

const PodcastPagesQuery = graphql`
  query PodcastPagesQuery {
    sanityGlobalPodcastList {
      subCopy
      title
      _rawListFooterComponents(resolveReferences: {maxDepth: 8})
      heroImage: _rawHeroImage(resolveReferences: {maxDepth: 8})
      seo: _rawSeo
      podcastLinks: _rawPodcastLinks(resolveReferences: {maxDepth: 8})
      listFooterComponents: _rawListFooterComponents(resolveReferences: {maxDepth: 8})
    }
  }
`;

const PodcastsListTemplate: React.FC<PodcastsProps> = ({articles, currentPage, numPages}: PodcastsProps) => {
  const data = useStaticQuery(PodcastPagesQuery);

  const globalPodcastContent = data.sanityGlobalPodcastList;

  const Posts = articles.map((item, key) => {
    return (
      <GridCard
        key={key}
        title={item?.title}
        category={item?.editorialCategory?.title}
        image={item?.heroImage}
        slug={item?.slug?.current}
        cardColClasses={'grid-list_item '}
        imageClasses={'grid-list_image '}
        contentClasses={'grid-list_content'}
        titleClasses={'type-epsilon mt-3'}
        eyebrowClasses={'grid-list_eyebrow '}
        editorialCategory={item?.editorialCategory}
        isPodcast={item?.isPodcast}
      />
    );
  });

  return (
    <>
      <SEOWithQuery
        title={data.seo?.seoMetaTitle || data.title || 'Podcasts'}
        description={data.seo?.seoDescription || undefined}
        image={data.seo?.seoImage?.asset?.url || undefined}
      />
      <div className="bg-white">
        <InsightsNavigation bgColor={'bg-white'} isListing={true} />
        <div className="bg-white">
          <section
            className="px-gutter text-white text-center pt-16 pb-20 lg:pt-32 lg:pb-40 relative"
            data-module="podcast-hero"
          >
            <div className="absolute w-full h-full inset-0 bg-green-dark">
              {!!globalPodcastContent.heroImage?.asset?.url && (
                <img
                  className="object-cover h-full w-full opacity-50 background-blur-sm"
                  src={globalPodcastContent.heroImage.asset.url}
                  alt={globalPodcastContent.heroImage.alText || globalPodcastContent.heroImage.asset.filename}
                />
              )}
            </div>
            <div className="wrapper-default grid grid-cols-4 md:grid-cols-12 gap-8">
              <div className="col-span-full md:col-span-8 md:col-start-3">
                <h2 className="type-beta mb-5">{globalPodcastContent?.title}</h2>
                <div className="prose">{globalPodcastContent?.subCopy}</div>
                <div className="mt-5 lg:mt-7">
                  <PodcastModal globalPodcastContent={globalPodcastContent} />
                </div>
              </div>
            </div>
          </section>
          <section className={`px-gutter module-spacing`} data-module="article-grid-list">
            <div className="grid-list">
              {Posts}
              {numPages > 1 && (
                <div className="col-span-full">
                  <div className="flex items-center justify-center w-full flex-col md:flex-row">
                    <div>
                      <Pagination currentPage={currentPage} numPages={numPages} slug={`${PODCAST.replace('/', '')}`} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          {!!globalPodcastContent.listFooterComponents && (
            <SupportingContent supportingContent={globalPodcastContent.listFooterComponents} />
          )}
        </div>
      </div>
    </>
  );
};

export default PodcastsListTemplate;
