import * as React from 'react';

import {SanityEditorial, SanityLabelledImage, SanityEditorialHomepage, Maybe, SanityTopic} from 'types/generated';

import PodcastsListTemplate from './index';

export default ({
  pageContext,
}: {
  pageContext: {
    limit: number;
    currentPage: number;
    numPages: number;
    href: string;
    articles: [any];
  };
}): React.ReactElement => (
  <PodcastsListTemplate
    currentPage={pageContext.currentPage}
    limit={pageContext.limit}
    numPages={pageContext.numPages}
    articles={pageContext.articles}
  />
);
